import React, { useState, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import { Menu } from "antd";
import { AuthContext } from "context/AuthProvider";
import RadioGroup from "components/UI/RadioGroup/RadioGroup";
import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  LISTING_POSTS_PAGE_CAR,
  CONTACT_PAGE,
  LIST_RESERVATION,
  OBTENIR_APPLI,
  BLOG_PAGE,
  LIST_LOCATION,
} from "settings/constant";
import { getLocalCurrency, setLocalCurrency } from "context/currency";
import { DEFAULT_DEVISE } from "library/helpers/constUtils";
import MenuContainer from "./MainMenu.style";
import { FaDownload } from "react-icons/fa";
import { SharedDataContext } from "context/SharedDataProvider";

export default function MainMenu({ className }) {
  const { searchType } = useContext(SharedDataContext);
  const [defaultCurrency, setdefaultCyrrency] = useState(getLocalCurrency);
  if (!getLocalCurrency()) setLocalCurrency(DEFAULT_DEVISE);
  const handleDevise = (e) => {
    if (e && e.target.value) {
      setLocalCurrency(e.target.value);
      window.dispatchEvent(new Event("storage"));
    }
  };

  const { user } = useContext(AuthContext);

  let menuItems = [
    {
      label: (
        <Link
          style={{ height: "30px", marginTop: "-20px" }}
          disabled={false}
          to={OBTENIR_APPLI}
        >
          <span className="spanDowloadLink">
            <FaDownload style={{ marginRight: "5px" }} />
            Obtenir l'appli
          </span>
        </Link>
      ),
      key: "menu-0",
      className: "obtenirApp",
      disabled: false,
    },
    {
      label: <NavLink to={HOME_PAGE}>Accueil</NavLink>,
      key: "menu-1",
    },
    {
      label: (
        <>
          {searchType == "LOCATION" ? (
            <NavLink to={LISTING_POSTS_PAGE_CAR}>Voitures</NavLink>
          ) : (
            <NavLink to={LISTING_POSTS_PAGE}>Hôtels</NavLink>
          )}
        </>
      ),
      key: "menu-2",
    },
    {
      label: <NavLink to={CONTACT_PAGE}>Contact</NavLink>,
      key: "menu-3",
    },
    {
      label: <NavLink to={BLOG_PAGE}>Nos blogs</NavLink>,
      key: "menu-6",
    },
    {
      label: (
        //<label for="radio1">Books</label>
        <RadioGroup
          style={{ width: "100px" }}
          onChange={handleDevise}
          defaultValue={defaultCurrency}
          buttonStyle="solid"
          options={[
            { label: "€", value: "€" },
            { label: "Ar", value: "Ar" },
          ]}
          className="btnSwitchDevise"
        />
      ),
      key: "menu-4",
    },
  ];

  let withReservation = [
    {
      label: (
        <Link
          style={{ height: "30px", marginTop: "-20px" }}
          disabled={false}
          to={OBTENIR_APPLI}
        >
          <span className="spanDowloadLink">
            <FaDownload style={{ marginRight: "5px" }} />
            Obtenir l'appli
          </span>
        </Link>
      ),
      key: "menu-0",
      className: "obtenirApp",
      disabled: false,
    },
    {
      label: <NavLink to={HOME_PAGE}>Accueil</NavLink>,
      key: "menu-1",
    },
    {
      label: (
        <>
          {searchType == "LOCATION" ? (
            <NavLink to={LISTING_POSTS_PAGE_CAR}>Voitures</NavLink>
          ) : (
            <NavLink to={LISTING_POSTS_PAGE}>Hôtels</NavLink>
          )}
        </>
      ),
      key: "menu-2",
    },
    {
      label: <>{ searchType == 'LOCATION' ?<NavLink to={LIST_LOCATION}>Location</NavLink>:<NavLink to={LIST_RESERVATION}>Réservation</NavLink>}</>,
      key: "menu-5",
    },
    {
      label: <NavLink to={CONTACT_PAGE}>Contact</NavLink>,
      key: "menu-3",
    },
    {
      label: <NavLink to={BLOG_PAGE}>Nos blogs</NavLink>,
      key: "menu-6",
    },
    {
      label: (
        //<label for="radio1">Books</label>
        <RadioGroup
          style={{ width: "100px" }}
          onChange={handleDevise}
          defaultValue={defaultCurrency}
          buttonStyle="solid"
          options={[
            { label: "€", value: "€" },
            { label: "Ar", value: "Ar" },
          ]}
          className="btnSwitchDevise"
        />
      ),
      key: "menu-4",
    },
  ];

  if (user?.roles && user.roles.includes("ROLE_CLIENT")) {
    menuItems = withReservation;
  }

  return (
    <MenuContainer>
      <Menu className={className} items={menuItems} />
    </MenuContainer>
  );
  // return (
  //   <Menu>
  //     {menuItems.map((item) =>
  //       item.subMenuItems ? (
  //         <Menu.SubMenu key={item.key} title={item.label} popupOffset={[-70, 50]}>
  //           {item.subMenuItems.map((subItem) => (
  //             <Menu.Item key={subItem.key} onClick={subItem.onClick}>
  //               {subItem.label}
  //             </Menu.Item>
  //           ))}
  //         </Menu.SubMenu>
  //       ) : (
  //         <Menu.Item key={item.key}>
  //           {item.label}
  //         </Menu.Item>
  //       )
  //     )}
  //   </Menu>
  // )
}
