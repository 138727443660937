export const CURRENCY_ARIARY = 'Ariary';
export const CURRENCY_EURO = 'Euro';
export const IMAGE_PLACEHOLDER= '/images/image-placeholder.jpg';
export const LIMIT_SIZE_PAGINATION = process.env.REACT_APP_PAGE_SIZE??10;
export const NB_HOTEL_IMAGE = process.env.REACT_APP_NB_HOTEL_IMAGE??10;
export const NB_CHAMBRE_IMAGE = process.env.REACT_APP_NB_CHAMBRE_IMAGE??3;
export const LIMIT_SIZE_CHAMBRE_PAGINATION = 8;
export const DEFAULT_START_PAGE = 0;
export const MIN_INPUT_SEARCH_LENGTH = 3;
export const HIDE_PAYEMENT = Object.freeze({
    orange: false,
    mvola: false,
    cb: true
});
export const TITRE_LISTE_HOTEL = 'Liste des hôtels';
export const TITRE_LISTE_VOITURE = 'Liste des voitures';
export const TITRE_OFFRE_MOMENT = 'Offres du moment';
export const MAP_DEFAULT = Object.freeze({
    lat : -18.934905563330403,
    lng : 47.565494860033276,
    zoom : 6,
    zoomWithLocation : 19,
    displayGoogleMap: false,
    displayStreetMap: true,
    markerImage: "/images/marker-map.png"
});
export const TITRE_TABLEAU_RESERVATION = "Liste des réservations";
export const DEFAULT_DEVISE = "Ar";

export const DEFAULT_FILTRE_PRICE = Object.freeze({
    'Ar': {
        min: 0,
        max: 1000000
    }
    ,
    '€': {
        min: 0,
        max: 1500
    }
});

export const LIBELLE_RESERVATION_DURREE = Object.freeze([
    {
        libelle: '> 72h',
        color: '#D7B2F5'
    },
    {
        libelle: '72h',
        color: '#59D0E3'
    },
    {
        libelle: '48h',
        color: '#DCAF65'
    },
    {
        libelle: 'A rembourser',
        color: '#ECE447'
    },
    {
        libelle: 'Remboursement effectué',
        color: '#C2BBB7'
    },
    {
        libelle: 'Confirmé avec le partenaire',
        color: '#9BF38F'
    }
]);

export const HOTEL_FORME_JURIQUE=[
    {value: "E.I.", label:'E.I.'},
    {value: "S.A.R.L.", label:'S.A.R.L.'},
    {value: "S.A.R.L.U.", label:'S.A.R.L.U.'},
    {value: "S.A.", label:'S.A.'},
    {value: "S.N.C.", label:'S.N.C.'},
    {value: "S.C.S.", label:'S.C.S.'},
    {value: "S.C.", label:'S.C.'},
    {value: "G.I.E.", label:'G.I.E.'},
    {value: "S.D.E.T.", label:'S.D.E.T.'},
]
// Entreprise Individuelle (EI) 
// Société à Responsabilité Limitée (SARL)
// Société Anonyme (SA)
// Société en Nom Collectif (SNC) 
// Société en Commandite Simple (SCS) 
// Société Civile (SC) 
// Groupement d'Intérêt Economique (GIE)
// Société de Droit Etranger (SDET)
// Société à Responsabilité Limitée Unipersonnelle (SARLU)
    
export const RESERVATION_TYPE_DAY_USE = 'DAY_USE';
export const RESERVATION_TYPE_SEJOUR = 'SEJOUR';
export const DAY_USE_HORAIRE_APRES_MIDI = 'APRES_MIDI';
export const DAY_USE_HORAIRE_MATIN = 'MATIN';
export const HORAIRE_DAY_USE = Object.freeze([
    {
        id: 0,
        label: 'Matin',
        value:DAY_USE_HORAIRE_MATIN
    },{
        id: 1,
        label: 'Après-midi',
        value: DAY_USE_HORAIRE_APRES_MIDI
    }
]);

export const DAY_USE_MATIN = 'MATIN';
export const DAY_USE_APRES_MIDI = 'APRES-MIDI';
export const DAY_SEMAINE = Object.freeze([
    {
      label: "Lundi",
      value: "Lundi",
    },
    {
      label: "Mardi",
      value: "Mardi",
    },
    {
      label: "Mercredi",
      value: "Mercredi",
    },
    {
      label: "Jeudi",
      value: "Jeudi",
    },
    {
      label: "Vendredi",
      value: "Vendredi",
    },
    {
      label: "Samedi",
      value: "Samedi",
    },
    {
      label: "Dimanche",
      value: "Dimanche",
    },
  ]);
export const FORMAT_DATE_DMY = 'DD-MM-yyyy';
export const FORMAT_DATE_YMD = 'yyyy-MM-DD';
export const DAY_SEMAINE_OBJECT_NUMBER = {
  'lundi': {
    number: 1
  },
  'mardi': {
    number: 2
  } ,
  'mercredi': {
    number: 3
  },
  'jeudi': {
    number: 4
  },
  'vendredi': {
    number: 5
  },
  'samedi': {
    number: 6
  },
  'dimanche': {
    number: 0
  }
};
export const DATE_FORMAT_MMYYY = 'MM/YYYY';
export const TITRE_DISPONIBILITE_CHAMBRE = "Disponibilité";
export const INFO_DISPONIBILITE_CHAMBRE_LIBRE = "chambre libre";
export const INFO_DISPONIBILITE_CHAMBRE_LIBRES = "chambres libres";
export const INFO_DISPONIBILITE_CHAMBRE_OCCUPEE = "chambre occupée";
export const INFO_DISPONIBILITE_CHAMBRE_OCCUPEES = "chambres occupées";
export const FORM_DECIMAL_PATERN = /^[0-9.]*$/; // /^[0-9,. ]*$/;
export const BASE_URL = process.env.REACT_APP_API_URL;