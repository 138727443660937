import React, { useContext } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { AuthContext } from "context/AuthProvider";
import Layout from "containers/Layout/Layout";
import Loader from "components/Loader/Loader";
import { loadState } from "library/helpers/localStorage";
import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  LISTING_POSTS_PAGE_CAR,
  SINGLE_POST_PAGE,
  // DETAILS_POST_PAGE,
  AGENT_PROFILE_PAGE,
  AGENT_PROFILE_FAVORITE,
  AGENT_PROFILE_CONTACT,
  PRICING_PLAN_PAGE,
  PRIVACY_PAGE,
  LOGIN_PAGE,
  REGISTRATION_PAGE,
  FORGET_PASSWORD_PAGE,
  ADMIN_PARTENAIRE_PAGE,
  ADMIN_PARTENAIRE_HOTEL,
  SIGN_PARTENAIRE_PAGE,
  // DETAILS_PAIEMENT_PAGE,
  // DETAILS_ANNULATION_PAGE,
  ADMIN_PARTENAIRE_RESERVATION_LIST,
  ADMIN_RESERVATION_GLOBAL_VIEW,
  ADMIN_PARTENAIRE_CHAMBRE,
  ADMIN_PARTENAIRE_CHAMBRE_EDIT,
  ADMIN_PARTENAIRE_CHAMBRE_TYPE_HEBERGEMENT,
  ADMIN_PARTENAIRE_CHAMBRE_TYPE_HEBERGEMENT_EDIT,
  CONTACT_PAGE,
  CONDITION_PAGE,
  CHANGE_PASSWORD_PAGE,
  CHANGE_PASSWORD_CLIENT_PAGE,
  SUPER_ADMIN_LIST_DOSSIER,
  SUPER_ADMIN_LIST_HOTEL,
  SUPER_ADMIN_LIST_USER,
  SUPER_ADMIN_PARAMETRE,
  SUPER_ADMIN_INDEX,
  SUPER_ADMIN_TRACE_UTILISATION,
  SUPER_ADMIN_VILLE,
  RESERVATION_PAGE,
  RESERVATION_ACCEPTED_CLIENT,
  ADD_PAGE,
  SUPER_ADMIN_EQUIPEMENT,
  SUPER_ADMIN_CATEGORIE_HOTEL,
  SUPER_ADMIN_CATEGORIE_EQUIPEMENT,
  SUPER_ADMIN_RESERVATIONS,
  SUPER_ADMIN_CHAMBRE_CATEGORIE_EQUIPEMENT,
  SUPER_ADMIN_CHAMBRE_EQUIPEMENT,
  SUPER_ADMIN_SERVICE,
  SUPER_ADMIN_CATEGORIE_SERVICE,
  SUPER_ADMIN_CHAMBRE_CATEGORIE_SERVICE,
  SUPER_ADMIN_CHAMBRE_SERVICE,
  SUPER_ADMIN_CHAMBRE_TYPE_HEBERGEMENT,
  LIST_RESERVATION,
  SUPER_ADMIN_ACCESSIBILITE,
  ADMIN_PARTENAIRE_USER,
  ACTIVATION_COMPTE_CLIENT,
  SUPER_ADMIN_DEVISE,
  SUCCES_STRIPE,
  CANCELED_STRIPE,
  ADMIN_HOTEL_COMMENTAIRE,
  ADMIN_HOTEL_PROMOTION,
  SUPER_ADMIN_HOTEL_PROMOTION,
  ADMIN_PARTENAIRE_MESDOCUMENTS,
  ADMIN_PARTENAIRE_RESILIER_CONTRAT,
  ADMIN_RESERVATION_DISPONIBILITE_VIEW,
  OBTENIR_APPLI,
  REDIRECT_APPLI,
  SUPER_ADMIN_ARTICLES,
  SUPER_ADMIN_CATEGORIE_ARTICLES,
  BLOG_PAGE,
  SUPER_ADMIN_ICONES,
  CONDITION_PAGE_PARTENAIRE,
  SUPER_ADMIN_MODE_PAIEMENT,
  PAIEMENT_EFFECTUE,
  PAIEMENT_EFFECTUE_LOCATION,
  ADMIN_PARTENAIRE_EXTRA,
  SUPER_ADMIN_OWNER_VOITURE,
  SUPER_ADMIN_TYPE_VOITURE,

  ADMIN_LOCATION,
  ADMIN_LOCATION_GESTION_PARTENAIRE,
  ADMIN_LOCATION_GESTION_VOITURE,
  SUPER_ADMIN_OPTION_VOITURE,
  ADMIN_LOCATION_GESTION_RESERVATION,
  SUPER_ADMIN_RESA_VOITURE,
  LOCATION_PAGE,
  ADMIN_LOCATION_USER,
  SUCCES_STRIPE_LOCATION,
  LIST_LOCATION,
  ADMIN_PARTENAIRE_LOCATION_MESDOCUMENTS,
  ADMIN_PARTENAIRE_LOCATION_RESILIER_CONTRAT
} from "./settings/constant";
import { startsWith } from "lodash";

//token verification
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

// protected route
function RequireAuth({ children }) {
  let { loggedIn, logOut } = useContext(AuthContext);
  let location = useLocation();
  let type = "client";
  if (startsWith(location.pathname, "/admin/")) {
    type = "utilisateur";
  }
  const user = loadState("user");
  if (user) {
    const decodedJwt = parseJwt(user.token);
    if (decodedJwt.exp * 1000 < Date.now()) {
      logOut();
      return (
        <Navigate to={LOGIN_PAGE} state={{ from: location, type: type }} />
      );
    }
  }
  if (!loggedIn) {
    return <Navigate to={LOGIN_PAGE} state={{ from: location, type: type }} />;
  }
  return children;
}

// public routes
const HomePage = React.lazy(() => import("containers/Home/Home"));
const ListingPage = React.lazy(() => import("containers/Listing/Listing"));
const ListingCarPage = React.lazy(() =>
  import("containers/ListingCar/ListingCar")
);
const LocationPage = React.lazy(() => import("containers/LocationVoiture/location.voiture"));
const ObtenirAppli = React.lazy(() =>
  import("containers/AppMobile/ObtenirAppli")
);
const SuccessStripeLocation = React.lazy(() => import("containers/LocationVoiture/location.voiture.succes"));
const PaiementEffectueLocation = React.lazy(() => import("containers/LocationVoiture/PaiementEffectueLocation"));

const RedirectAppli = React.lazy(() =>
  import("containers/AppMobile/RedirectionAppli")
);
const SinglePageView = React.lazy(() =>
  import("containers/SinglePage/SinglePageView")
);

const ContactPage = React.lazy(() => import("containers/Contact/Contact"));
const AgentDetailsViewPage = React.lazy(() =>
  import("containers/Agent/AccountDetails/AgentDetailsViewPage")
);
const AgentItemLists = React.lazy(() =>
  import("containers/Agent/AccountDetails/AgentItemLists")
);
const AgentFavItemLists = React.lazy(() =>
  import("containers/Agent/AccountDetails/AgentFavItemLists")
);
const AgentContact = React.lazy(() =>
  import("containers/Agent/AccountDetails/AgentContact")
);
const PricingPage = React.lazy(() => import("containers/Pricing/Pricing"));
const PrivacyPage = React.lazy(() => import("containers/Privacy/Privacy"));
const SignInPage = React.lazy(() => import("containers/Auth/SignIn/SignIn"));
const SignUpPage = React.lazy(() => import("containers/Auth/SignUp/SignUp"));
const SignPartenaire = React.lazy(() =>
  import("containers/Auth/SignPartenaire/SignPartenaire")
);
const ForgetPasswordPage = React.lazy(() =>
  import("containers/Auth/ForgetPassword")
);
const ChangePasswordPage = React.lazy(() =>
  import("containers/Auth/ChangePassword")
);
const ChangePasswordClientPage = React.lazy(() =>
  import("containers/Auth/ChangePasswordClient")
);
const SignConditionPage = React.lazy(() =>
  import("containers/Auth/SignCondition")
);
const SignConditionPagePartenaire = React.lazy(() =>
  import("containers/Auth/SignConditionPartenaire")
);
const ActivationClientPage = React.lazy(() =>
  import("containers/Auth/ActivationClient")
);
// const SignCondition = React.lazy(() => import('containers/Auth/Condition112'));
const NotFound = React.lazy(() => import("containers/404/404"));
// protected route
const ReservationPage = React.lazy(() =>
  import("containers/Reservation/Reservation")
);

const LocationVoiturePage = React.lazy(() =>
  import("containers/LocationVoiture/location.voiture")
);

const ReservationListPage = React.lazy(() =>
  import("containers/Reservation/ReservationList")
);

const LocationListPage = React.lazy(() =>
  import("containers/LocationVoiture/location.voiture.list")
);

const ReservationSuccessPage = React.lazy(() =>
  import("containers/Reservation/Success")
);

const ReservationCanceledPage = React.lazy(() =>
  import("containers/Reservation/Canceled")
);

const ReservationAcceptedPage = React.lazy(() =>
  import("containers/Reservation/AcceptedClient")
);

const AddListing = React.lazy(() => import("containers/AddListing/AddListing"));
const AdminPartenairePage = React.lazy(() =>
  import("containers/Admin/Partenaire/Index")
);
const AdminPartenaireChambre = React.lazy(() =>
  import("containers/Admin/Partenaire/Chambre/ChambreTable")
);
const AdminPartenaireHotel = React.lazy(() =>
  import("containers/Admin/Partenaire/Hotel/EditHotel")
);
const AdminPartenaireReservation = React.lazy(() =>
  import("containers/Admin/Partenaire/Reservation/ReservationTable")
);
const AdminPartenaireReservationGlobalView = React.lazy(() =>
  import(
    "containers/Admin/Partenaire/Reservation/globalView/ReservationGlobalView"
  )
);
const AdminPartenaireDisponibiliteView = React.lazy(() =>
  import(
    "containers/Admin/Partenaire/Reservation/disponibilite/DisponibiliteView.js"
  )
);
const AdminPartenaireChambreForm = React.lazy(() =>
  import("containers/Admin/Partenaire/Chambre/ChambreForm")
);
const AdminPartenaireChambreTypeHebergement = React.lazy(() =>
  import("containers/Admin/Partenaire/Chambre/ChambreTypeHebergementTable")
);
const AdminPartenaireChambreTypeHebergementEdit = React.lazy(() =>
  import("containers/Admin/Partenaire/Chambre/ChambreTypeHebergementForm")
);
const AdminPartenaireOffreMoment = React.lazy(() =>
  import("containers/Admin/Partenaire/Offre/OffreDuMomentView")
);
const AdminPartenaireCommentaire = React.lazy(() =>
  import("containers/Admin/Partenaire/Commentaire/CommentaireView")
);

const AdminPartenaireMesDocuments = React.lazy(() =>
  import("containers/Admin/Partenaire/MesDocuments/DocumentsView")
);
const AdminPartenaireResilierContrat = React.lazy(() =>
  import("containers/Admin/Partenaire/Contrat/ResilierContratForm")
);

const SuperAdminIndex = React.lazy(() =>
  import("containers/SuperAdmin/AdminIndex")
);
const SuperAdminDossier = React.lazy(() =>
  import("containers/SuperAdmin/Dossier/DossierTable")
);
const SuperAdminHotel = React.lazy(() =>
  import("containers/SuperAdmin/Hotel/HotelTable")
);
const SuperAdminUser = React.lazy(() =>
  import("containers/SuperAdmin/User/UserTable")
);
const SuperAdminParametre = React.lazy(() =>
  import("containers/SuperAdmin/Parametre/Parametre")
);
const SuperAdminTraceUtilisation = React.lazy(() =>
  import("containers/SuperAdmin/Trace/TraceUtilisationTable")
);
const SuperAdminVille = React.lazy(() =>
  import("containers/SuperAdmin/Ville/VilleTable")
);
const SuperAdminEquipHebergement = React.lazy(() =>
  import("containers/SuperAdmin/Hotel/EquipementHebergement/EquipementTable")
);
const SuperAdminCategorieHotel = React.lazy(() =>
  import("containers/SuperAdmin/Hotel/CategorieHotelTable")
);
const SuperAdminCategorieEquipementHebergement = React.lazy(() =>
  import(
    "containers/SuperAdmin/Hotel/EquipementHebergement/CategorieEquipementTable"
  )
);
const SuperAdminReservations = React.lazy(() =>
  import("containers/SuperAdmin/Reservation/ReservationTable")
);
const SuperAdminEquipementChambre = React.lazy(() =>
  import("containers/SuperAdmin/Hotel/Chambre/Equipement/EquipementTable")
);
const SuperAdminCategorieEquipementChambre = React.lazy(() =>
  import(
    "containers/SuperAdmin/Hotel/Chambre/Equipement/CategorieEquipementTable"
  )
);
const SuperAdminServiceHebergement = React.lazy(() =>
  import("containers/SuperAdmin/Hotel/ServiceHebergement/ServiceTable")
);
const SuperAdminCategorieServiceHebergement = React.lazy(() =>
  import("containers/SuperAdmin/Hotel/ServiceHebergement/CategorieServiceTable")
);
const SuperAdminServiceChambre = React.lazy(() =>
  import("containers/SuperAdmin/Hotel/Chambre/Service/ServiceTable")
);
const SuperAdminCategorieServiceChambre = React.lazy(() =>
  import("containers/SuperAdmin/Hotel/Chambre/Service/CategorieServiceTable")
);
const SuperAdminTypeHebergementChambre = React.lazy(() =>
  import("containers/SuperAdmin/Hotel/Chambre/TypeHebergementTable")
);
const SuperAdminAccessibilite = React.lazy(() =>
  import("containers/SuperAdmin/Accessibilite/AccessibiliteTable")
);
const SuperAdminDevise = React.lazy(() =>
  import("containers/SuperAdmin/Devise/DeviseTable")
);
const SuperAdminOffre = React.lazy(() =>
  import("containers/Admin/Partenaire/Offre/OffreDuMomentView")
);

const Articles = React.lazy(() =>
  import("containers/SuperAdmin/Articles/ArticlePage")
);
const CategorieArticle = React.lazy(() =>
  import("containers/SuperAdmin/Articles/CategorieArticlePage")
);

const BlogPage = React.lazy(() => import("containers/Blogs/BlogsPage"));

const PageIcones = React.lazy(() =>
  import("containers/SuperAdmin/Icones/IconePage")
);

const PaiementEffectue = React.lazy(() =>
  import("containers/Reservation/PaiementEffectue")
);

const ModePaiement = React.lazy(() =>
  import("containers/SuperAdmin/Paiement/ModePaiement")
);

const PageExtra = React.lazy(() =>
  import("containers/Admin/Partenaire/Chambre/Extra/ExtraPage")
);

const PageVoitureProprietaire = React.lazy(() =>
  import("containers/SuperAdmin/Voitures/ProprietaireTable")
);
const PageVoitureType = React.lazy(() =>
  import("containers/SuperAdmin/Voitures/TypeVoitureTable")
);

const PageOptionVoiture = React.lazy(() =>
  import("containers/SuperAdmin/Voitures/OptionVoitureTable")
);

const PageSuperAdminVoitureResa = React.lazy(() =>
  import("containers/SuperAdmin/Voitures/Reservation/ReservationTable")
);

//Admin Page Partenaire Location Voiture
const AdminPartenaireLocationPage = React.lazy(() =>
  import("containers/Admin/PartenaireLocation/Index")
);
const PageAdminLocationPartenaire = React.lazy(() =>
  import("containers/Admin/PartenaireLocation/Partenaire/partenaire")
);
const PageAdminLocationVoiture = React.lazy(() =>
  import("containers/Admin/PartenaireLocation/Voiture/voiture.index")
);
const PageAdminLocationReservation = React.lazy(() =>
  import("containers/Admin/PartenaireLocation/Reservation/reservation.location.index")
);
const AdminPartenaireLocationMesDocuments = React.lazy(() =>
  import("containers/Admin/PartenaireLocation/MesDocuments/DocumentsView")
);
const AdminPartenaireLocationResilierContrat = React.lazy(() =>
  import("containers/Admin/PartenaireLocation/Contrat/ResilierContratForm")
);

export default function AppRoutes() {
  return (
    <Routes>
      <Route path={HOME_PAGE} element={<Layout />}>
        <Route
          index
          element={
            <React.Suspense fallback={<Loader />}>
              <HomePage />
            </React.Suspense>
          }
        />
        <Route
          path={OBTENIR_APPLI}
          element={
            <React.Suspense fallback={<Loader />}>
              <ObtenirAppli />
            </React.Suspense>
          }
        />
        <Route
          path={REDIRECT_APPLI}
          element={
            <React.Suspense fallback={<Loader />}>
              <RedirectAppli />
            </React.Suspense>
          }
        />
        <Route
          path={LISTING_POSTS_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <ListingPage />
            </React.Suspense>
          }
        />
        <Route
          path={LISTING_POSTS_PAGE_CAR}
          element={
            <React.Suspense fallback={<Loader />}>
              <ListingCarPage />
            </React.Suspense>
          }
        />
        <Route
          path={LOCATION_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <LocationPage />
            </React.Suspense>
          }
        />
        <Route
          path={SUCCES_STRIPE}
          element={
            <React.Suspense fallback={<Loader />}>
              <ReservationSuccessPage />
            </React.Suspense>
          }
        />
        <Route
          path={PAIEMENT_EFFECTUE_LOCATION}
          element={
            <React.Suspense fallback={<Loader />}>
              <PaiementEffectueLocation />
            </React.Suspense>
          }
        />
        <Route
          path={SUCCES_STRIPE_LOCATION}
          element={
            <React.Suspense fallback={<Loader />}>
              <SuccessStripeLocation />
            </React.Suspense>
          }
        />
        <Route
          path={`${SINGLE_POST_PAGE}/:idHotel/:slug`}
          element={
            <React.Suspense fallback={<Loader />}>
              <SinglePageView />
            </React.Suspense>
          }
        />
        {/* <Route
          path={`${DETAILS_POST_PAGE}`}
          element={
            <React.Suspense fallback={<Loader />}>
              <DetailsPageView />
            </React.Suspense>
          }
        >
        <Route
          path={`${DETAILS_POST_PAGE}`}
          element={
            <React.Suspense fallback={<Loader />}>
              <DetailsProprieteView />
            </React.Suspense>
          }
        />
        <Route
          path={`${DETAILS_PAIEMENT_PAGE}`}
          element={
            <React.Suspense fallback={<Loader />}>
              <DetailsPagePaiement />
            </React.Suspense>
          }
        />
        <Route
          path={DETAILS_ANNULATION_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <DetailsPageAnnulation />
            </React.Suspense>
          }
        />
        </Route> */}

        <Route
          path={CONTACT_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <ContactPage />
            </React.Suspense>
          }
        />
        <Route
          path={`${BLOG_PAGE}`}
          element={
            <React.Suspense fallback={<Loader />}>
              <BlogPage />
            </React.Suspense>
          }
        />
        <Route
          path={`${BLOG_PAGE}/:slug`}
          element={
            <React.Suspense fallback={<Loader />}>
              <BlogPage />
            </React.Suspense>
          }
        />

        {/* Nested routes for agent page */}
        <Route
          path={AGENT_PROFILE_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <AgentDetailsViewPage />
            </React.Suspense>
          }
        >
          <Route
            path={AGENT_PROFILE_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <AgentItemLists />
              </React.Suspense>
            }
          />

          <Route
            path={AGENT_PROFILE_FAVORITE}
            element={
              <React.Suspense fallback={<Loader />}>
                <AgentFavItemLists />
              </React.Suspense>
            }
          />
          <Route
            path={AGENT_PROFILE_CONTACT}
            element={
              <React.Suspense fallback={<Loader />}>
                <AgentContact />
              </React.Suspense>
            }
          />
        </Route>
        {/* end of Nested routes */}
        <Route
          path={PRICING_PLAN_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <PricingPage />
            </React.Suspense>
          }
        />
        <Route
          path={PRIVACY_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <PrivacyPage />
            </React.Suspense>
          }
        />
        <Route
          path={LOGIN_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <SignInPage />
            </React.Suspense>
          }
        />
        <Route
          path={REGISTRATION_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <SignUpPage />
            </React.Suspense>
          }
        />
        <Route
          path={CONDITION_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <SignConditionPage />
            </React.Suspense>
          }
        />
        <Route
          path={CONDITION_PAGE_PARTENAIRE}
          element={
            <React.Suspense fallback={<Loader />}>
              <SignConditionPagePartenaire />
            </React.Suspense>
          }
        />

        <Route
          path={FORGET_PASSWORD_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <ForgetPasswordPage />
            </React.Suspense>
          }
        />
        <Route
          path={RESERVATION_ACCEPTED_CLIENT}
          element={
            <React.Suspense fallback={<Loader />}>
              <ReservationAcceptedPage />
            </React.Suspense>
          }
        />
        <Route
          path={`${SINGLE_POST_PAGE}/:idHotel/:slug/${RESERVATION_PAGE}/:room`}
          element={
            <React.Suspense fallback={<Loader />}>
              <ReservationPage />
            </React.Suspense>
          }
        />
        <Route
          path={`${LOCATION_PAGE}/:idVoiture`}
          element={
            <React.Suspense fallback={<Loader />}>
              <LocationVoiturePage />
            </React.Suspense>
          }
        />
        <Route
          path={LIST_RESERVATION}
          element={
            <React.Suspense fallback={<Loader />}>
              <ReservationListPage />
            </React.Suspense>
          }
        />
        <Route
          path={LIST_LOCATION}
          element={
            <React.Suspense fallback={<Loader />}>
              <LocationListPage />
            </React.Suspense>
          }
        />
        <Route
          path={SUCCES_STRIPE}
          element={
            <React.Suspense fallback={<Loader />}>
              <ReservationSuccessPage />
            </React.Suspense>
          }
        />
        <Route
          path={PAIEMENT_EFFECTUE}
          element={
            <React.Suspense fallback={<Loader />}>
              <PaiementEffectue />
            </React.Suspense>
          }
        />
        <Route
          path={CANCELED_STRIPE}
          element={
            <React.Suspense fallback={<Loader />}>
              <ReservationCanceledPage />
            </React.Suspense>
          }
        />
        <Route
          path={ADD_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <AddListing />
            </React.Suspense>
          }
        />
        <Route
          path={CHANGE_PASSWORD_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <ChangePasswordPage />
            </React.Suspense>
          }
        />
        <Route
          path={CHANGE_PASSWORD_CLIENT_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <ChangePasswordClientPage />
            </React.Suspense>
          }
        />
        <Route
          path={ACTIVATION_COMPTE_CLIENT}
          element={
            <React.Suspense fallback={<Loader />}>
              <ActivationClientPage />
            </React.Suspense>
          }
        />
        {/* Protected routes */}
        {/* Admin Partenaire page */}
        <Route
          path={ADMIN_PARTENAIRE_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <RequireAuth>
                <AdminPartenairePage />
              </RequireAuth>
            </React.Suspense>
          }
        >
          <Route
            path={ADMIN_PARTENAIRE_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <AdminPartenaireHotel />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={ADMIN_PARTENAIRE_HOTEL}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <AdminPartenaireHotel />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={ADMIN_PARTENAIRE_CHAMBRE}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <AdminPartenaireChambre />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={ADMIN_HOTEL_PROMOTION}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <AdminPartenaireOffreMoment />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={ADMIN_HOTEL_COMMENTAIRE}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <AdminPartenaireCommentaire />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={ADMIN_PARTENAIRE_MESDOCUMENTS}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <AdminPartenaireMesDocuments />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={ADMIN_PARTENAIRE_RESILIER_CONTRAT}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <AdminPartenaireResilierContrat />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={ADMIN_PARTENAIRE_EXTRA}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <PageExtra />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={ADMIN_PARTENAIRE_CHAMBRE_EDIT}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <AdminPartenaireChambreForm />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={ADMIN_PARTENAIRE_CHAMBRE_TYPE_HEBERGEMENT}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <AdminPartenaireChambreTypeHebergement />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={ADMIN_PARTENAIRE_CHAMBRE_TYPE_HEBERGEMENT_EDIT}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <AdminPartenaireChambreTypeHebergementEdit />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={ADMIN_PARTENAIRE_RESERVATION_LIST}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <AdminPartenaireReservation />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={ADMIN_RESERVATION_GLOBAL_VIEW}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <AdminPartenaireReservationGlobalView />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={ADMIN_RESERVATION_DISPONIBILITE_VIEW}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <AdminPartenaireDisponibiliteView />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={ADMIN_PARTENAIRE_USER}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <SuperAdminUser />
                </RequireAuth>
              </React.Suspense>
            }
          />
        </Route>
        {/* Super Admin*/}
        <Route
          path={SUPER_ADMIN_INDEX}
          element={
            <React.Suspense fallback={<Loader />}>
              <RequireAuth>
                <SuperAdminIndex />
              </RequireAuth>
            </React.Suspense>
          }
        >
          <Route
            path={SUPER_ADMIN_LIST_DOSSIER}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <SuperAdminDossier />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_LIST_HOTEL}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <SuperAdminHotel />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_LIST_USER}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <SuperAdminUser />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_PARAMETRE}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <SuperAdminParametre />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_TRACE_UTILISATION}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <SuperAdminTraceUtilisation />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_VILLE}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <SuperAdminVille />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_EQUIPEMENT}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <SuperAdminEquipHebergement />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_CATEGORIE_HOTEL}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <SuperAdminCategorieHotel />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_CATEGORIE_EQUIPEMENT}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <SuperAdminCategorieEquipementHebergement />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_RESERVATIONS}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <SuperAdminReservations />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_CHAMBRE_CATEGORIE_EQUIPEMENT}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <SuperAdminCategorieEquipementChambre />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_CHAMBRE_EQUIPEMENT}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <SuperAdminEquipementChambre />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_SERVICE}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <SuperAdminServiceHebergement />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_CATEGORIE_SERVICE}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <SuperAdminCategorieServiceHebergement />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_CHAMBRE_SERVICE}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <SuperAdminServiceChambre />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_CHAMBRE_CATEGORIE_SERVICE}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <SuperAdminCategorieServiceChambre />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_CHAMBRE_TYPE_HEBERGEMENT}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <SuperAdminTypeHebergementChambre />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_ACCESSIBILITE}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <SuperAdminAccessibilite />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_DEVISE}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <SuperAdminDevise />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_MODE_PAIEMENT}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <ModePaiement />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_HOTEL_PROMOTION}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <SuperAdminOffre />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_CATEGORIE_ARTICLES}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <CategorieArticle />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_ARTICLES}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <Articles />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_ICONES}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <PageIcones />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_OWNER_VOITURE}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <PageVoitureProprietaire />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_TYPE_VOITURE}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <PageVoitureType />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={SUPER_ADMIN_OPTION_VOITURE}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <PageOptionVoiture />
                </RequireAuth>
              </React.Suspense>
            }
          />

          <Route
            path={SUPER_ADMIN_RESA_VOITURE}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <PageSuperAdminVoitureResa />
                </RequireAuth>
              </React.Suspense>
            }
          />
        </Route>
        {/* Admin Partenaire voiture routes*/}
        <Route
          path={ADMIN_LOCATION}
          element={
            <React.Suspense fallback={<Loader />}>
              <RequireAuth>
                <AdminPartenaireLocationPage />
              </RequireAuth>
            </React.Suspense>
          }
        >
          <Route
            path={ADMIN_LOCATION_GESTION_PARTENAIRE}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <PageAdminLocationPartenaire />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={ADMIN_LOCATION_GESTION_VOITURE}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <PageAdminLocationVoiture />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={ADMIN_LOCATION_USER}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <SuperAdminUser />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={ADMIN_LOCATION_GESTION_RESERVATION}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <PageAdminLocationReservation />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={ADMIN_PARTENAIRE_LOCATION_MESDOCUMENTS}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <AdminPartenaireLocationMesDocuments />
                </RequireAuth>
              </React.Suspense>
            }
          />
          <Route
            path={ADMIN_PARTENAIRE_LOCATION_RESILIER_CONTRAT}
            element={
              <React.Suspense fallback={<Loader />}>
                <RequireAuth>
                  <AdminPartenaireLocationResilierContrat />
                </RequireAuth>
              </React.Suspense>
            }
          />
        </Route>


        {/* end of Protected routes*/}
        <Route
          path="*"
          element={
            <React.Suspense fallback={<Loader />}>
              <NotFound />
            </React.Suspense>
          }
        />
      </Route>
      <Route
        path={SIGN_PARTENAIRE_PAGE}
        element={
          <React.Suspense fallback={<Loader />}>
            <SignPartenaire />
          </React.Suspense>
        }
      />
    </Routes>
  );
}
