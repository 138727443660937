// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import partenaireReducer from '../redux/reducers/partenaireSlice'

const store = configureStore({
  reducer: {
    partenaire: partenaireReducer,
  },
});

export default store;
