import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  typePartenariat: null,
  formData: {},
};

const partenaireSlice = createSlice({
  name: 'partenaire',
  initialState,
  reducers: {
    setTypePartenariat: (state, action) => {
      state.typePartenariat = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
  },
});

export const { setTypePartenariat, setFormData } = partenaireSlice.actions;
export default partenaireSlice.reducer;
