import React, { useContext } from 'react';
import { FaFolder, FaCar, FaBoxOpen, FaUndo, FaCalendar, FaUser } from 'react-icons/fa';
import { NavLink} from 'react-router-dom';
import { Menu} from 'antd';

import {
    ADMIN_LOCATION_GESTION_PARTENAIRE,
    ADMIN_LOCATION_GESTION_RESERVATION,
    ADMIN_LOCATION_GESTION_VOITURE,
    ADMIN_LOCATION_USER,
    SUPER_ADMIN_OWNER_VOITURE,
    ADMIN_PARTENAIRE_LOCATION_RESILIER_CONTRAT,
    ADMIN_PARTENAIRE_LOCATION_MESDOCUMENTS
} from 'settings/constant';
import { AuthContext } from 'context/AuthProvider';

const AdminPartenaireLocationSideMenu = () => {
    const { user } = useContext(AuthContext);

    let navigations = [
        {
            label: <NavLink to={ADMIN_LOCATION_GESTION_PARTENAIRE}>Partenaire</NavLink>,
            key: 'loueur_voiture',
            icon: <FaFolder className='Loueur' />
        },
        {
            label: <NavLink to={ADMIN_LOCATION_GESTION_VOITURE}>Voitures</NavLink>,
            key: 'voiture',
            icon: <FaCar className='Voiture' />
        },
        {
            label: <NavLink to={ADMIN_LOCATION_GESTION_RESERVATION}>Réservations</NavLink>,
            key: 'reservation',
            icon: <FaCalendar className='Reservation' />
        }
    ];

      if(user.roles.includes('ROLE_SUPER_ADMIN')
        || user.roles.includes('ROLE_ADMIN_HOTEL')) {
            navigations.push(
          {
            label: <NavLink title='Mes documents' to={ADMIN_PARTENAIRE_LOCATION_MESDOCUMENTS}>Mes documents</NavLink>,
            key: 'mesDocumens',
            icon:<FaFolder className='MesDocuments'/>
          }
        );
        navigations.push(
          {
            label: <NavLink title='Résilier contrat' to={ADMIN_PARTENAIRE_LOCATION_RESILIER_CONTRAT}>Résilier contrat</NavLink>,
            key: 'resilierContrat',
            icon:<FaFolder className='ResilierContrat'/>
          }
        );
      }

    if(user.roles.includes('ROLE_SUPER_ADMIN')
      || user.roles.includes('ROLE_MANAGER')
      || user.roles.includes('ROLE_AGENT_COM')
      || user.roles.includes('ROLE_ADMIN_HOTEL')) {
        navigations.push({
            label: <NavLink to={ADMIN_LOCATION_USER}>Utilisateurs</NavLink>,
            key: 'user',
            icon:<FaUser className='user'/>
        })
      }

    if(user.roles.includes('ROLE_SUPER_ADMIN')
        || user.roles.includes('ROLE_MANAGER')
        || user.roles.includes('ROLE_AGENT_COM')) {
          navigations.push({
            label: <NavLink to={SUPER_ADMIN_OWNER_VOITURE}>Retour</NavLink>,
            key: 'Undu',
            icon:<FaUndo className='Undu'/>
          })
    }
    
    return (
        <>
            <Menu
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['Loueur']}
                mode="inline"
                items={navigations}
            />
        </>
    );
};

export default AdminPartenaireLocationSideMenu;