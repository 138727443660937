
import { QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 3, // Réessayer les requêtes en cas d’échec
        onError: (error) => {
          console.error('An error occurred:', error.message);
        },
      },
      mutations: {
        onError: (error) => {
          console.error('Mutation error:', error.message);
        },
      },
    },
  });

export default queryClient;
