import React, { Fragment } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Layout as LayoutWrapper } from 'antd';
import useWindowSize from 'library/hooks/useWindowSize';
import LayoutProvider from 'context/LayoutProvider';
import {startsWith} from 'lodash';
import {
  LISTING_POSTS_PAGE,
  LOGIN_PAGE,
  REGISTRATION_PAGE,
  AGENT_PROFILE_PAGE,
  AGENT_ACCOUNT_SETTINGS_PAGE,
  ADD_HOTEL_PAGE,
  PRICING_PLAN_PAGE,
  SINGLE_POST_PAGE,
  PRIVACY_PAGE,
  CHANGE_PASSWORD_PAGE,
  CHANGE_PASSWORD_CLIENT_PAGE,
  FORGET_PASSWORD_PAGE,
  AGENT_IMAGE_EDIT_PAGE,
  AGENT_PASSWORD_CHANGE_PAGE,
  ADMIN_PARTENAIRE_PAGE,
  CONDITION_PAGE,
  SUPER_ADMIN_INDEX,
  ACTIVATION_COMPTE_CLIENT,
  CANCELED_STRIPE,
  SUCCES_STRIPE,
  RESERVATION_PAGE,
  CONDITION_PAGE_PARTENAIRE,
  ADMIN_LOCATION
} from 'settings/constant';
import Header from './Header/Header';
import HeaderAdmin from './Header/HeaderAdmin'
import Footer from './Footer/Footer';
const { Content } = LayoutWrapper;


export function AdminLayout() {
  const { width } = useWindowSize();
  return (
    <Fragment>
      <HeaderAdmin />
      <Content>
        <Outlet />
      </Content>
      {width < 991 && <div style={{height:"35px"}}></div> }
    </Fragment>
  );
}

export default function Layout() {
  let location = useLocation();
  const { width } = useWindowSize();
  const singlePageUrlFromConst = SINGLE_POST_PAGE.split('/');
  const singlePageUrlFormLocation = location.pathname.split('/');

  return (
    <LayoutProvider>
      {location.pathname === LOGIN_PAGE ||
      location.pathname === CHANGE_PASSWORD_PAGE ||
      location.pathname === ACTIVATION_COMPTE_CLIENT ||
      location.pathname === CHANGE_PASSWORD_CLIENT_PAGE ||
      location.pathname === FORGET_PASSWORD_PAGE ||
      location.pathname === CONDITION_PAGE ||
      location.pathname === CONDITION_PAGE_PARTENAIRE ||
      location.pathname === CANCELED_STRIPE ||
      location.pathname === SUCCES_STRIPE ||
      location.pathname === REGISTRATION_PAGE ? (
        <Content>
          <Outlet />
        </Content>
      ) : (
        startsWith(location.pathname,ADMIN_PARTENAIRE_PAGE) 
        ||  startsWith(location.pathname,SUPER_ADMIN_INDEX
           || startsWith(location.pathname, ADMIN_LOCATION)
        )?(
          <AdminLayout/>
        ):
        <Fragment>
          <Header />
          <Content>
            <Outlet />
          </Content>
          {location.pathname === LISTING_POSTS_PAGE ||
          location.pathname === PRICING_PLAN_PAGE ||
          location.pathname === ADD_HOTEL_PAGE ||
          location.pathname === AGENT_PROFILE_PAGE ||
          location.pathname === CHANGE_PASSWORD_PAGE ||
          location.pathname === ACTIVATION_COMPTE_CLIENT ||
          location.pathname === CHANGE_PASSWORD_CLIENT_PAGE ||
          location.pathname === FORGET_PASSWORD_PAGE ||
          location.pathname === CANCELED_STRIPE ||
          location.pathname === SUCCES_STRIPE ||
          location.pathname === PRIVACY_PAGE ||
          location.pathname ===
            `${AGENT_ACCOUNT_SETTINGS_PAGE + AGENT_IMAGE_EDIT_PAGE}` ||
          location.pathname ===
            `${AGENT_ACCOUNT_SETTINGS_PAGE + AGENT_PASSWORD_CHANGE_PAGE}` ||
          location.pathname === AGENT_ACCOUNT_SETTINGS_PAGE
          
          || (
            singlePageUrlFormLocation.join().includes('hotel') &&  
            singlePageUrlFormLocation.join().includes(RESERVATION_PAGE) && 
            singlePageUrlFormLocation.length === 5
            ) 

          ? (
            <div style={{ height: '33px' }} />
          ) : (
            <Fragment>
              <Footer />
              {singlePageUrlFormLocation[1] === singlePageUrlFromConst[1] && (
                <Fragment>
                  {width < 1200 && <div style={{ height: '74px' }} />}
                </Fragment>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </LayoutProvider>
  );
}
