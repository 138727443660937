import { DAY_USE_HORAIRE_MATIN } from "library/helpers/constUtils";
import { loadState } from "library/helpers/localStorage";
import { createContext, useState } from "react";

export const SharedDataContext = createContext("");

export const SharedDataProvider = (props) => {
	const [isFiltreDayUseChecked, setIsFiltreDayUseChecked] = useState(false);
	const [filtreDayUseDate, setFiltreDayUseDate] = useState(null);
	const [filtreDayUseMomentJournee, setFiltreDayUseMomentJournee] = useState(DAY_USE_HORAIRE_MATIN);
	const [scrollPosition, setScrollPosition] = useState(0);
	const [currentHotelPage, setCurrentHotelPage] = useState(0);

	const [saveLastLoadedHotel, setSaveLastLoadedHotel] = useState([]);
	const [isFromDetail, setIsFromDetail] = useState(false);

	 const [searchType, setSearchType] = useState(loadState("search-type") || 'SEJOUR');

	const resetFiltreDayUse = () => {
		setIsFiltreDayUseChecked(false);
		setFiltreDayUseDate(null);
		setFiltreDayUseMomentJournee(DAY_USE_HORAIRE_MATIN);
	}

	return (
		<SharedDataContext.Provider
			value={{
				isFiltreDayUseChecked,
				setIsFiltreDayUseChecked,
				filtreDayUseDate,
				setFiltreDayUseDate,
				filtreDayUseMomentJournee,
				setFiltreDayUseMomentJournee,
				resetFiltreDayUse,
				scrollPosition, 
				setScrollPosition,
				currentHotelPage, 
				setCurrentHotelPage,
				saveLastLoadedHotel, 
				setSaveLastLoadedHotel,
				isFromDetail, 
				setIsFromDetail,
				searchType,
				setSearchType
			}}
		>
			<>{props.children}</>
		</SharedDataContext.Provider>
	);
};
