import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'themes/default.theme';
import GlobalStyles from 'themes/global.style';
import AuthProvider from 'context/AuthProvider';
import AppRoutes from './router';
import 'antd/dist/reset.css';
import { ConfigProvider } from 'antd';
import fr_FR from 'antd/locale/fr_FR';
import { SharedDataProvider } from 'context/SharedDataProvider';
import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from './settings/queryClient';
import { Provider } from 'react-redux';
import store from './redux/store';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'; 

import TagManager from 'react-gtm-module';
const tagManagerArgs = { gtmId: 'G-2QFPBERYNT' }; // Remplacez par votre ID GTM
TagManager.initialize(tagManagerArgs);

const App = () => (
  <ThemeProvider theme={theme}>
     <Provider store={store}>
      <ConfigProvider locale={fr_FR}>
        <QueryClientProvider client={queryClient}>
          <React.Fragment>
            <GlobalStyles />
            <BrowserRouter>
              <AuthProvider>
                <SharedDataProvider>
                  <AppRoutes />
                </SharedDataProvider>
              </AuthProvider>
            </BrowserRouter>
          </React.Fragment>
          {/* <ReactQueryDevtools initialIsOpen={true} /> */}
        </QueryClientProvider>
      </ConfigProvider>
    </Provider>
  </ThemeProvider>
);
const root = createRoot(document.getElementById('root'));
root.render(<App />);
